(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("socket.io-client"));
	else if(typeof define === 'function' && define.amd)
		define(["socket.io-client"], factory);
	else if(typeof exports === 'object')
		exports["FacilMap"] = factory(require("socket.io-client"));
	else
		root["FacilMap"] = root["FacilMap"] || {}, root["FacilMap"]["Client"] = factory(root["io"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__705__) => {
return 